/**
 * Requires jQuery and jQuery.cookie (https://github.com/carhartl/jquery-cookie)
 * - Adds an 'options' parameter to $cookieStore.put and $cookieStore.remove.
 * - Default options can be set by calling $cookieStoreProvider.setDefaultOptions
 */
angular
	.module('utils.cookies', ['ngCookies'])

	.constant('CookieNames', {
		benchmark: {
			filterCurriculum: '__Imagine.Cloud.Portal.Benchmark.FilterCurriculum'
		},
		growth: {
			filterPeriod: '__Imagine.Cloud.Portal.Growth.FilterPeriod',
			filterView: '__Imagine.Cloud.Portal.Growth.FilterView',
			filterQualifier: '__Imagine.Cloud.Portal.Growth.FilterQualifier',
			filterGrade: '__Imagine.Cloud.Portal.Growth.FilterGrade',
			filtersAdvanced: '__Imagine.Cloud.Portal.Growth.FiltersAdvanced',
			filtersBasic: '__Imagine.Cloud.Portal.Growth.FiltersBasic',
			selectedGradeLevels: '__Imagine.Cloud.Portal.Growth.SelectedGradeLevels',
			selectedGroups: '__Imagine.Cloud.Portal.Growth.SelectedGroups',
			selectedStudents: '__Imagine.Cloud.Portal.Growth.SelectedStudents'
		},
		progress: {
			splash: '__Imagine.Cloud.Portal.Progress.Splash',
			overview: {
				filters: '__Imagine.Cloud.Portal.Progress.Overview.Filters',
				curriculumArea: '__Imagine.Cloud.Portal.Progress.Overview.CurriculumArea'
			},
			student: {
				filters: '__Imagine.Cloud.Portal.Progress.Student.Filters'
			},
			tab: '__Imagine.Cloud.Portal.Progress.Tab'
		},
		usage: {
			filters: '__Imagine.Cloud.Portal.Usage.Filters',
			admin: {
				filters: '__Imagine.Cloud.Portal.Usage.Admin.Filters'
			},
			sessions: '__Imagine.Cloud.Portal.Usage.Sessions'
		},
		authentication: {
			alias: '__Imagine.Cloud.Portal.Alias',
			lastUser: '__Imagine.Cloud.Portal.LastUser',
			user: '__Imagine.Cloud.Portal.User',
			token: '__Imagine.Cloud.Portal.User.Token',
			userName: '__Imagine.Cloud.Portal.UserName',
			userRootOrganization: '__Imagine.Cloud.Portal.RootOrganization',
			userAdminOrganization: '__Imagine.Cloud.Portal.UserAdminOrganization',
			userOrganizations: '__Imagine.Cloud.Portal.UserOrganizations',
			userId: '__Imagine.Cloud.Portal.User.ID'
		},
		manager: {
			navigation: '__Imagine.Cloud.Portal.Manager.Navigation',
			students: {
				studentSource: '__Imagine.Cloud.Portal.Manager.Students.Source'
			}
		},
		management: {
			navigation: '__Imagine.Cloud.Portal.Management.Navigation',
			search: {
				returnState: '__Imagine.Cloud.Portal.Management.Search.ReturnState'
			}
		},
		sequencer: {
			oralLanguages: '__Imagine.Cloud.Portal.Sequencer.OralLanguages'
		}
	})

	.provider('$cookieStore', [
		function() {
			var that = this;
			that.defaultOptions = {};

			that.setDefaultOptions = function(options) {
				that.defaultOptions = options;
			};

			that.$get = function() {
				return {
					get: function(name) {
						var jsonCookie = jQuery.cookie(name);
						if (jsonCookie) {
							if (jsonCookie.search(/^([\[\{]).*([\]\}])$/) >= 0) {
								return angular.fromJson(jsonCookie);
							}
							return _.str.trim(jsonCookie, '"');
						}
						return null;
					},
					put: function(name, value, options) {
						options = jQuery.extend({}, that.defaultOptions, options);
						jQuery.cookie(name, angular.toJson(value), options);
					},
					remove: function(name, options) {
						options = jQuery.extend({}, that.defaultOptions, options);
						jQuery.removeCookie(name, options);
					}
				};
			};
		}
	]);
