import { ClientId, OverrideStorageKeys, ScopeClaims, toEnvironment } from '@imaginelearning/web-auth';
import { shouldShowNewGrowthReport } from '../../../common/utils/src/growth_date_check';

(function() {
	angular
		.module('portal', [
			'ngStorage',
			'ngAnimate',
			'ui.router',
			'validation',
			'validation.rule',

			'api',
			'api.pendo',
			'api.analytics',
			'api.application.user',
			'api.authentication',
			'api.featureFlags',
			'api.iladmin',
			'api.interceptors.fetchCreatedItem',
			'api.interceptors.notLoggedIn',
			'api.logger',
			'api.urls',
			'portal.service',

			'utils.cookies',
			'utils.date',
			'utils.featureFlipper',
			'utils.localization',
			'utils.logging',
			'utils.safariPrintFix',
			'utils.touchDetector',
			'utils.version',
			'utils.webfont',
			'product.names',
			'utils.error.messages',

			'ui.directives.authenticated.content',
			'ui.directives.clickOutside',
			'ui.directives.disableClick',
			'ui.directives.fillWindowHeight',
			'ui.directives.match',
			'ui.directives.matchDocumentHeight',
			'ui.directives.matchWindowHeight',
			'ui.directives.featureFlag',
			'ui.form.utils',
			'ui.form.validSubmit',
			'ui.logoutNotifier',
			'logout.redirect',
			'ui.modal',
			'ui.modal.common',
			'ui.notifications',
			'ui.popup',
			'ui.print',
			'ui.svg.svgutils',
			'ui.tabhandler',
			'ui.tutorials',
			// 'ui.unsupported',
			'ui.updateNotifier',
			'ui.assist.mode.bar',
			'ui.sticky.headers',

			'ui.templates',
			'portal.templates',
			'config',
			'portal.routing',
			'portal.legacyRouting',
			'portal.localization',

			'manager.modal.factory',
			'route.helpers',
			'user.monogram',

			'dashboard',
			'ildashboard',
			'portal.manager'
		])
		.config(configure)
		.run(run)
		.controller('AppCtrl', ctrl);

	configure.$inject = [
		'$cookieStoreProvider',
		'$httpProvider',
		'$uiViewScrollProvider',
		'AnalyticsProvider',
		'AuthenticationServiceProvider',
		'CurrentDateProvider',
		'DEFAULT_LOCATION',
		'ENV',
		'FeatureFlipperProvider',
		'LogConfigProvider',
		'logoutNotifierProvider',
		'NotificationFactoryProvider',
		'NotLoggedInInterceptorProvider',
		'updateNotifierConfigProvider',
		'urlsProvider',
		'versionProvider',
		'$compileProvider',
		'OidcUserManagerProvider'
	];

	function configure(
		$cookieStoreProvider,
		$httpProvider,
		$uiViewScrollProvider,
		AnalyticsProvider,
		AuthenticationServiceProvider,
		CurrentDateProvider,
		DEFAULT_LOCATION,
		ENV,
		FeatureFlipperProvider,
		LogConfigProvider,
		logoutNotifierProvider,
		NotificationFactoryProvider,
		NotLoggedInInterceptorProvider,
		updateNotifierConfigProvider,
		urlsProvider,
		versionProvider,
		$compileProvider,
		OidcUserManagerProvider
	) {
		$cookieStoreProvider.setDefaultOptions({ path: '/' });
		$uiViewScrollProvider.useAnchorScroll();
		LogConfigProvider.level = ENV.logLevel;
		LogConfigProvider.remoteLevel = ENV.remoteLogLevel;
		NotificationFactoryProvider.environment = ENV.name;
		updateNotifierConfigProvider.configUrl = '/assets/config.js';
		versionProvider.set(ENV.version);
		CurrentDateProvider.overrideCurrentDateAs = ENV.overrideCurrentDateAs;
		FeatureFlipperProvider.setFeatures(ENV.enabledDevelopmentFeatures);
		AuthenticationServiceProvider.defaultUrl = DEFAULT_LOCATION;

		// Configure URLs
		urlsProvider.environment = ENV.name;
		urlsProvider.loginApiUrl = ENV.loginApiUrl;
		urlsProvider.apiUrl = ENV.apiUrl;
		urlsProvider.authApiUrl = ENV.authApiUrl;
		urlsProvider.imlpApiUrl = ENV.imlpApiUrl;
		urlsProvider.managementApiUrl = ENV.managementApiUrl;
		urlsProvider.featureFlagsApiUrl = ENV.featureFlagsApiUrl;
		urlsProvider.mppLoginUrl = ENV.mppLoginUrl;
		urlsProvider.sequencerApiUrl = ENV.sequencerApiUrl;
		// urlsProvider.studentDataApiUrl = ENV.studentDataApiUrl;
		urlsProvider.reportsApiUrl = ENV.reportsApiUrl;
		urlsProvider.singleSignOnApiUrl = ENV.singleSignOnApiUrl;
		urlsProvider.importApiUrl = ENV.importApiUrl;
		urlsProvider.unityLaunchUrl = ENV.unityLaunchUrl;
		urlsProvider.portalUrl = ENV.portalUrl;
		urlsProvider.loginUrl = ENV.loginUrl;
		urlsProvider.loggerApiUrl = ENV.loggerApiUrl;
		urlsProvider.galileoUrl = ENV.galileoUrl;
		urlsProvider.setupWizardUrl = ENV.setupWizardUrl;
		urlsProvider.enyayUrl = ENV.enyayUrl;
		urlsProvider.imagineLearningUniversityUrl = ENV.imagineLearningUniversityUrl;
		urlsProvider.dsdsDashboardUrl = ENV.dsdsDashboardUrl;
		urlsProvider.maestroUrl = ENV.maestroUrl;

		// Configure analytics
		AnalyticsProvider.googleAnalyticsTrackingID = ENV.googleAnalyticsTrackingID;
		AnalyticsProvider.employeeGoogleAnalyticsTrackingID = ENV.employeeGoogleAnalyticsTrackingID;

		//set keepalive timeout
		logoutNotifierProvider.keepalivePeriod = ENV.keepaliveSeconds || 120;
		logoutNotifierProvider.timeoutPeriod = ENV.timeoutSeconds || 3600;
		logoutNotifierProvider.warningPeriod = ENV.timeoutCountdown || 60;

		// Configure OidcUserManager
		OidcUserManagerProvider.configure({
			env: toEnvironment(ENV.name),
			client_id: ClientId.Portal,
			post_logout_redirect_uri: `${ENV.authApiUrl}/signin`,
			redirect_uri: `${ENV.portalUrl}/signin`,
			claims: [
				ScopeClaims.OpenId,
				ScopeClaims.ManagerApi,
				ScopeClaims.ReportsApi,
				ScopeClaims.PlaylistApi,
				ScopeClaims.KvsApi,
				ScopeClaims.PermissionsApi,
				ScopeClaims.SequencerApi,
				ScopeClaims.ReportsCoreApi,
				ScopeClaims.ReportsReadingCoreApi,
				ScopeClaims.ArtifactApi,
				ScopeClaims.StudentDataApi,
				ScopeClaims.SettingsApi,
				ScopeClaims.TransferApi,
				ScopeClaims.NweaApi,
				ScopeClaims.AssessmentApi,
				ScopeClaims.SpinnakerApi,
				ScopeClaims.LearnosityApi,
				ScopeClaims.DotCMSApi,
				ScopeClaims.GraphCMSApi,
				ScopeClaims.RadApi,
				ScopeClaims.SisenseApi,
				ScopeClaims.WebReportingApi
			]
		});

		$httpProvider.interceptors.push('NotLoggedInInterceptor');
		$httpProvider.interceptors.push('FetchCreatedItemInterceptor');

		//$httpProvider.useApplyAsync(true);

		// Solution from http://stackoverflow.com/questions/17289195/angularjs-post-data-to-external-rest-api
		// Enable CORS.
		$httpProvider.defaults.useXDomain = true;
		// Delete header from client:
		delete $httpProvider.defaults.headers.common['X-Requested-With'];

		//only enable debug data in a development build
		$compileProvider.debugInfoEnabled(ENV.name && ENV.name === 'development' ? true : false);
	}

	run.$inject = [
		'$cookieStore',
		'$localStorage',
		'$location',
		'$rootScope',
		'$state',
		'$timeout',
		'$window',
		'Analytics',
		'ApplicationUserService',
		'AuthenticationService',
		'CommonModal',
		'CookieNames',
		'DEFAULT_LOCATION',
		'ENV',
		'Localize',
		'loggerHandler',
		'logoutNotifier',
		'logoutRedirect',
		'NotificationFactory',
		'SVGUtils',
		'urls',
		'UserRole'
	];

	function run(
		$cookieStore,
		$localStorage,
		$location,
		$rootScope,
		$state,
		$timeout,
		$window,
		Analytics,
		ApplicationUserService,
		AuthenticationService,
		CommonModal,
		CookieNames,
		DEFAULT_LOCATION,
		ENV,
		Localize,
		loggerHandler,
		logoutNotifier,
		logoutRedirect,
		NotificationFactory,
		SVGUtils,
		urls,
		UserRole
	) {
		$rootScope.authenticating = false;

		activate();

		////////////////////

		function getUrlParams(url) {
			var vars = {};
			var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m, key, value) {
				vars[key] = value;
			});
			return vars;
		}

		function activate() {
			Analytics.initialize();

			loggerHandler.on();

			logoutRedirect.init(DEFAULT_LOCATION);

			$rootScope.$on('$stateChangeSuccess', onStateChangeSuccess);

			// Listen for location changes to fix the URLs for SVG filters
			$rootScope.$on('$locationChangeSuccess', function(event, newUrl, oldUrl, newState, oldState) {
				var params = getUrlParams(oldUrl);
				var requestedProduct = _.get(params, 'requestedProduct');
				if (requestedProduct && requestedProduct === 'espanol') {
					$localStorage.requestedProduct = '/' + requestedProduct;
				}
				reconcilePageCSS($state.current);
				SVGUtils.normalizeCSSFilterURLs();
			});
		}

		function reconcilePageCSS(toState) {
			var classes = {
				body: [],
				content: [],
				secondaryHeader: []
			};

			// Walk up state hierarchy pulling out any CSS classes defined
			// in state config data
			var state = toState;
			do {
				// if (state.data && state.name !== 'dashboard.growth') {
				if (state.data) {
					// jshint -W083
					angular.forEach(classes, function(value, key) {
						if (state.data[key + 'Class']) {
							if (angular.isString(state.data[key + 'Class'])) {
								classes[key] = classes[key].concat(state.data[key + 'Class'].split(' '));
							} else {
								classes[key] = classes[key].concat(state.data[key + 'Class']);
							}
						}
					});
				}
				state = $state.get(state.parent);
			} while (state);

			var user = ApplicationUserService.getUser();
			if (user) {
				var role = ApplicationUserService.getUserRole();
				switch (role) {
					case UserRole.ilAdmin:
					case UserRole.ilSiteAdmin:
						classes.body.push('admin');
						classes.body.push('iladmin_admin');
						classes.body.push('ilsiteadmin_admin');
						break;

					case UserRole.admin:
						classes.body.push('admin');
						break;

					case UserRole.teacher:
						classes.body.push('teacher');
						break;

					case UserRole.parent:
						classes.body.push('parent');
						break;
				}
			}

			$rootScope.cssClasses = {
				body: _.compact(_.uniq(classes.body)),
				content: _.compact(_.uniq(classes.content)),
				secondaryHeader: _.compact(_.uniq(classes.secondaryHeader))
			};
		}

		function setPageTitle(toState) {
			var pageTranslationId = toState.data && toState.data.pageTitleTranslationId ? toState.data.pageTitleTranslationId : '';
			$rootScope.pageTitle = Localize.translateInstant(pageTranslationId);
		}

		function onStateChangeSuccess(event, toState, toParams, fromState, fromParams) {
			NotificationFactory.clear();
			reconcilePageCSS(toState);
			setPageTitle(toState);
		}
	}

	ctrl.$inject = [
		'$cookieStore',
		'$http',
		'urls',
		'DateUtils',
		'$filter',
		'$log',
		'$q',
		'$rootScope',
		'$scope',
		'$state',
		'$timeout',
		'$window',
		'ApplicationUserService',
		'AuthenticationService',
		'AuthenticationServiceEvents',
		'CookieNames',
		'CurrentDate',
		'DEFAULT_STATE',
		'FormManager',
		'ILAdmin',
		'logoutNotifier',
		'ManagerModalFactory',
		'ModalManager',
		'NotificationFactory',
		'$localStorage',
		'Organization',
		'User',
		'UserRole',
		'PendoService',
		'PrintEventsService',
		'RouteHelpers',
		'ApiProducts',
		'Portal',
		'ENV',
		'FeatureFlagsService'
	];

	function ctrl(
		$cookieStore,
		$http,
		urls,
		DateUtils,
		$filter,
		$log,
		$q,
		$rootScope,
		$scope,
		$state,
		$timeout,
		$window,
		ApplicationUserService,
		AuthenticationService,
		AuthenticationServiceEvents,
		CookieNames,
		CurrentDate,
		DEFAULT_STATE,
		FormManager,
		ILAdmin,
		logoutNotifier,
		ManagerModalFactory,
		ModalManager,
		NotificationFactory,
		$localStorage,
		Organization,
		User,
		UserRole,
		PendoService,
		PrintEventsService,
		RouteHelpers,
		ApiProducts,
		Portal,
		ENV,
		FeatureFlagsService
	) {
		// product
		$scope.application = Portal;
		$rootScope.growthReportApi = undefined;
		$rootScope.$on('AuthenticationService.userLoggedIn', () => {
			const orgId = ApplicationUserService.getRootOrganization().id;
			const userSiteCode = ApplicationUserService.getUser().siteCode;
			$q.all([Organization.schoolYearSessions(orgId), FeatureFlagsService.evaluateKey('growth-report-literacy-api')])
				.then(([session, flagResult]) => {
					$rootScope.growthReportApi = !shouldShowNewGrowthReport(
						DateUtils.getCurrentSchoolYearDates(session),
						userSiteCode,
						CurrentDate
					)
						? undefined
						: flagResult;
					$rootScope.$broadcast(AuthenticationServiceEvents.growthReportRolloverLoaded);
				})
				.catch(error => {
					_.get(error, 'status') !== 404 ? NotificationFactory.error(error) : angular.noop();
				});
		});

		$rootScope.getAuthSource = () => {
			const authSource = localStorage.getItem('auth.authSource') || '';
			return authSource.match(/ilauth/i) ? 'ILAuth' : 'IMLP';
		};
		$rootScope.appendAuthSourceQueryParam = url => {
			return `${url}${url.indexOf('?') === -1 ? '?' : '&'}authSource=${$rootScope.getAuthSource()}`;
		};

		$rootScope.$on('$stateChangeSuccess', function(event, toState) {
			setProduct(toState);
		});

		function setProduct(state) {
			if (state.name.match(/dashboard/i)) {
				Portal.product =
					!Portal.products || Portal.products.length < 1 || Portal.products.includes(ApiProducts.ILE)
						? ApiProducts.ILE
						: ApiProducts.Spanish;
			} else if (state.name.match(/espanol/i) && Portal.products && Portal.products.includes(ApiProducts.Spanish)) {
				Portal.product = ApiProducts.Spanish;
			}
		}

		PrintEventsService.init();

		$scope.year = CurrentDate.get().getFullYear();

		$scope.getGlobalPrintTarget = function() {
			return $state.current.data && $state.current.data.displayName ? $state.current.data.displayName : $state.current.name;
		};

		//IL-268/PK-607 - Growth: Rotating Ipad causes charts/images to disappear
		//redraw the graph on screen orientation change so it doesn't vanish
		function onOrientationChange() {
			angular.element('body').css('-webkit-transform', 'scale(0.9999)');
			$timeout(function() {
				angular.element('body').css('-webkit-transform', 'scale(1.0)');
			}, 0);
		}

		angular.element($window).on('orientationchange', onOrientationChange);

		$scope.currentStateParent = function() {
			return $state.current.parent;
		};

		$scope.firstLetterMatcher = function(term, text) {
			return text.toUpperCase().indexOf(term.toUpperCase()) === 0;
		};

		$scope.userName = '';
		$scope.userDisplayName = '';
		$scope.managerName = 'apps.manager';
		$scope.userSiteCodes = false;
		$scope.userIsAdmin = false;
		$scope.userIsILAdmin = false;
		$scope.userHasStudents = undefined;

		$scope.currentUser = undefined;

		$rootScope.$on('CURRENT_USER_STUDENT_CHECK', function(event) {
			$scope.userHasStudentsCheck();
		});

		$scope.userHasStudentsCheck = function() {
			if (
				ApplicationUserService.getUserRole() === UserRole.ilAdmin ||
				ApplicationUserService.getUserRole() === UserRole.ilSiteAdmin
			) {
				return;
			}

			var id = ApplicationUserService.getUser().id;
			if (id) {
				User.studentsCount(id).then(function(students) {
					$scope.userHasStudents = students > 0;
				});
			}
		};
		$scope.isAuthenticated = function() {
			return ApplicationUserService.isAuthenticated();
		};

		var lastId,
			userDetailsChecked = false;
		$scope.updateUserDetails = function(user) {
			$scope.currentUser = user;
			$scope.userIsAdmin = user.role === UserRole.admin || user.role === UserRole.ilAdmin || user.role === UserRole.ilSiteAdmin;
			$scope.userIsILAdmin = user.role === UserRole.ilAdmin || ApplicationUserService.impersonating() === true;
			Portal.products = user.products;
			// If user does not have access to ILE try to send them to a product they can access
			if (user.products && user.products.length && !_.some(user.products, prod => prod === ApiProducts.ILE)) {
				const jwt = ApplicationUserService.getJwt();
				const authSource = jwt?.iss?.match?.(/edgenuity/gi) ? 'IMLP' : 'ILAuth';
				const url = urls.productSelection + (authSource === 'ILAuth' ? '?useIlAuth' : '');
				$window.location.replace(url);
			}

			setProduct($state.current);

			$scope.userHasStudentsCheck();
			$scope.userRootOrganization = $scope.currentUser.organizations[0];
			$scope.userIsImpersonating = ApplicationUserService.impersonating();
			$scope.userDisplayName = user.displayName;
			$scope.userName = user.username;
			$scope.managerName = user.role === UserRole.teacher ? 'apps.teachersManager' : 'apps.manager';
			$scope.userSiteCodes = user.siteCode;

			if (!lastId || user.id !== lastId) {
				lastId = user.id;
				PendoService.identify({
					enabled: ENV.literacyPendoEnabled,
					apiKey: ENV.literacyPendoApiKey
				});
			}
		};

		$scope.$watch(
			function() {
				return ApplicationUserService.getAuthenticatedUser();
			},
			function(user, lastUser) {
				if (user && (!userDetailsChecked || ApplicationUserService.isJwt())) {
					userDetailsChecked = true;
					if (
						!!localStorage[OverrideStorageKeys.ILE.overrideUser] &&
						localStorage[OverrideStorageKeys.ILE.overrideUser] !== ApplicationUserService.getUser().id
					) {
						delete localStorage[OverrideStorageKeys.ILE.overrideDate];
						delete localStorage[OverrideStorageKeys.ILE.overrideUser];
						var params = angular.copy($state.params);
						$state.go($state.current.name, params, { reload: true });
					} else {
						$scope.updateUserDetails(user);
					}
				}
			}
		);

		$scope.showChangePassword = function() {};

		$scope.saveNewPassword = function() {
			var user = ApplicationUserService.getUser();
			AuthenticationService.authenticate(user.username, $scope.changePassword.currentPassword).then(function(result) {
				if (result.success) {
					var newUserDetails = {
						id: user.id,
						password: $scope.changePassword.password
					};

					if (user.role === UserRole.ilAdmin || user.role === UserRole.ilSiteAdmin) {
						ILAdmin.patch(newUserDetails).then(
							function() {
								$scope.close();
								NotificationFactory.success({
									heading: $filter('translate')('login.resetPasswordSuccessHeader'),
									content: $filter('translateFormatString')('login.resetPasswordSuccessContent', user.username),
									closeAfter: 5
								});
							},
							function(response) {
								NotificationFactory.error(response);
							}
						);
					} else {
						User.patch(newUserDetails).then(
							function() {
								$scope.close();
								NotificationFactory.success({
									heading: $filter('translate')('login.resetPasswordSuccessHeader'),
									content: $filter('translateFormatString')('login.resetPasswordSuccessContent', user.username),
									closeAfter: 5
								});
							},
							function(response) {
								NotificationFactory.error(response);
							}
						);
					}
				} else {
					$scope.errorMessage = $filter('translate')('login.currentPassIncorrect');
				}
			});
		};

		$scope.isCurrent = function(name) {
			var matcher = new RegExp('\\b' + name + '\\b', 'gi');
			if ($state.$current.url && $state.$current.url.prefix && $state.$current.url.prefix.length > 1) {
				var x = $state.$current.url.prefix.match(matcher);
				return x && x.length > 0 ? true : false;
			}
			return false;
		};

		$scope.inCurrentRoute = function(name) {
			var matcher = new RegExp(name, 'g');
			if ($state.current && $state.current.name && $state.current.name.length) {
				var x = $state.current.name.match(matcher);
				return x && x.length > 0 ? true : false;
			}
			return false;
		};

		$scope.inReport = function(reportName) {
			return $state.current.name.match(reportName);
		};

		$scope.inDashboards = function() {
			if ($state.$current.url && $state.$current.url.prefix && $state.$current.url.prefix.length > 1) {
				var x = $state.$current.url.prefix.match(/dashboard./gi);
				return x && x.length > 0 ? true : false;
			}
			return false;
		};

		$scope.inOriginalReports = function() {
			var result = false;
			if ($state.$current.url && $state.$current.url.prefix && $state.$current.url.prefix.length > 1) {
				switch ($state.$current.url.prefix) {
					case '/progress/student':
					case '/progress/student/':
					case '/usage/admin':
					case '/usage/admin/':
					case '/usage/teacher':
					case '/usage/teacher/':
					case '/growth':
					case '/growth/':
					case '/portfolio/group':
					case '/portfolio/group/':
					case '/portfolio/artifact/':
					case '/portfolio/student/':
						result = true;
						break;

					default:
						result = false;
						break;
				}
			}
			return result;
		};
	}
})();
