/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { dateRangeService as dateService } from '@imaginelearning/web-ui-react';
import { faShareFromSquare } from '@fortawesome/pro-solid-svg-icons';
import {
	DateHelpers,
	DataFields,
	UsageServiceIntervals,
	ManagerEntityTypes,
	pdfReportService,
	organizationService
} from '@imaginelearning/web-services';
import { GrowthViews } from '@imaginelearning/web-reporting';
const { ApiProduct } = require('@imaginelearning/web-api-core');

angular
	.module('dashboard.organization', [
		'manager.modal.factory',
		'unassigned.students.manager',
		'test.ranges',
		'ui.notifications',
		'ui.loader',
		'ui.router',
		'utils.date',
		'utils.featureFlipper',
		'utils.gradeLevel',
		'il.web.components',
		'test.enablement',
		'test.enablement.organization',
		'api.urls',
		'api.application.user',
		'api.featureFlags',
		'api.assessment.provider',
		'api.reporting',
		'api.manager',
		'ui.directives.matchParentHeight',
		'react.components',
		'react.dependencies'
	])
	.config(config)
	.controller('OrganizationDashboardController', controller);

config.$inject = ['$stateProvider'];

function config($stateProvider) {
	$stateProvider
		.state('dashboard.organization', {
			url: '/organization/:id',
			controller: controller,
			controllerAs: 'model',
			templateUrl: 'dashboards/organization/organization.html',
			data: {
				bodyClass: 'dashboard',
				contentClass: 'dashboard organization',
				text: ''
			}
		})
		.state('dashboard.organization.overview', {
			url: '/overview',
			views: {
				'': {
					templateUrl: 'dashboards/organization/partials/overview.html'
				}
			}
		})
		.state('dashboard.organization.unassigned', {
			url: '/unassigned',
			views: {
				'': {
					templateUrl: 'dashboards/organization/partials/unassigned.students.html'
				}
			}
		})
		.state('dashboard.organization.usage', {
			url: '/usage',
			views: {
				'': {
					templateUrl: 'dashboards/organization/partials/usage.html'
				}
			}
		})
		.state('dashboard.organization.usage.average', {
			url: '/average?start&end',
			views: {
				'': {
					templateUrl: 'dashboards/organization/partials/usage.average.html'
				}
			}
		})
		.state('dashboard.organization.usage.predicted', {
			url: '/predicted?start&end',
			views: {
				'': {
					templateUrl: 'dashboards/organization/partials/usage.predicted.html'
				}
			}
		})
		.state('dashboard.organization.progress', {
			url: '/progress',
			views: {
				'': {
					templateUrl: 'dashboards/organization/partials/progress.html'
				}
			}
		})
		.state('dashboard.organization.progress.overview', {
			url: '/overview',
			views: {
				'': {
					templateUrl: 'dashboards/organization/partials/progress.overview.html'
				}
			}
		})
		.state('dashboard.organization.progress.programLevelGains', {
			url: '/programLevelGains?start&end&curriculum&grade',
			views: {
				'': {
					templateUrl: 'dashboards/organization/partials/progress.program.level.gains.html'
				}
			}
		})
		.state('dashboard.organization.progress.kready', {
			url: '/kready?start&end',
			views: {
				'': {
					templateUrl: 'dashboards/organization/partials/progress.kready.html'
				}
			}
		})
		.state('dashboard.organization.progress.cumulativePerformance', {
			url: '/cumulativePerformance?start&end',
			views: {
				'': {
					templateUrl: 'dashboards/organization/partials/progress.cumulative.performance.html'
				}
			}
		})
		.state('dashboard.organization.assessments', {
			url: '/assessments',
			views: {
				'': {
					templateUrl: 'dashboards/organization/partials/assessments.html'
				}
			}
		})
		.state('dashboard.organization.assessments.overview', {
			url: '/overview',
			views: {
				'': {
					templateUrl: 'dashboards/organization/partials/assessments.overview.html'
				}
			}
		})
		.state('dashboard.organization.assessments.growth', {
			url: '/growth?start&end',
			views: {
				'': {
					templateUrl: 'dashboards/organization/partials/assessments.growth.html'
				}
			}
		})
		.state('dashboard.organization.assessments.benchmark', {
			url: '/benchmark',
			views: {
				'': {
					templateUrl: 'dashboards/organization/partials/assessments.benchmark.html'
				}
			}
		})
		.state('dashboard.organization.assessments.benchmark.status', {
			url: '/status?start&end&grade&period',
			views: {
				'': {
					templateUrl: 'dashboards/organization/partials/assessments.benchmark.status.html'
				}
			}
		})
		.state('dashboard.organization.assessments.benchmark.gains', {
			url: '/gains?start&end&grade&qualifier&curriculum',
			views: {
				'': {
					templateUrl: 'dashboards/organization/partials/assessments.benchmark.gains.html'
				}
			}
		})
		.state('dashboard.organization.assessments.benchmark.performance', {
			url: '/performance?start&end&grade&qualifier&curriculum',
			views: {
				'': {
					templateUrl: 'dashboards/organization/partials/assessments.benchmark.performance.html'
				}
			}
		})
		.state('dashboard.organization.assessments.lexile', {
			url: '/lexile',
			views: {
				'': {
					templateUrl: 'dashboards/organization/partials/assessments.lexile.html'
				}
			}
		})
		.state('dashboard.organization.assessments.lexile.status', {
			url: '/status?start&end&grade&period',
			views: {
				'': {
					templateUrl: 'dashboards/organization/partials/assessments.lexile.status.html'
				}
			}
		})
		.state('dashboard.organization.assessments.lexile.gains', {
			url: '/gains?start&end&grade&qualifier&curriculum',
			views: {
				'': {
					templateUrl: 'dashboards/organization/partials/assessments.lexile.gains.html'
				}
			}
		})
		.state('dashboard.organization.assessments.lexile.performance', {
			url: '/performance?start&end&grade&qualifier',
			views: {
				'': {
					templateUrl: 'dashboards/organization/partials/assessments.lexile.performance.html'
				}
			}
		})
		.state('dashboard.organization.settings', {
			url: '/settings',
			views: {
				'': {
					templateUrl: 'dashboards/organization/partials/settings.html'
				}
			}
		});
}

controller.$inject = [
	'$scope',
	'$q',
	'urls',
	'$state',
	'$filter',
	'schoolYear',
	'NotificationFactory',
	'ApplicationUserService',
	'AuthenticationServiceEvents',
	'GradeLevel',
	'DateUtils',
	'CurrentDate',
	'Organization',
	'PendoService',
	'ReportQueue',
	'User',
	'DateRangeService',
	'FeatureFlipper',
	'FeatureFlagsService',
	'CsvDownloadModal',
	'GeneratePdfModal',
	'ReportPrinting',
	'ReportingAPI',
	'ManagerModalFactory',
	'GrowthGradeFilter',
	'GrowthQualifierFilter',
	'GrowthViewFilter',
	'TestRanges',
	'HttpBusyService',
	'AssessmentProvider',
	'AssessmentProviders',
	'$rootScope',
	'ReactDependenciesService',
	'$window',
	'ngDialog',
	'$localStorage'
];

function controller(
	$scope,
	$q,
	urls,
	$state,
	$filter,
	schoolYear,
	NotificationFactory,
	ApplicationUserService,
	AuthenticationServiceEvents,
	GradeLevel,
	DateUtils,
	CurrentDate,
	Organization,
	PendoService,
	ReportQueue,
	User,
	DateRangeService,
	FeatureFlipper,
	FeatureFlagsService,
	CsvDownloadModal,
	GeneratePdfModal,
	ReportPrinting,
	ReportingAPI,
	ManagerModalFactory,
	GrowthGradeFilter,
	GrowthQualifierFilter,
	GrowthViewFilter,
	TestRanges,
	HttpBusyService,
	AssessmentProvider,
	AssessmentProviders,
	$rootScope,
	ReactDependenciesService,
	$window,
	ngDialog,
	$localStorage
) {
	var model = this;

	model.reactDependenciesService = ReactDependenciesService;
	model.organizationUsageReportProps = undefined;
	model.organizationUsagePredictedProps = undefined;
	model.organizationProgressReportProps = undefined;
	model.kindergartenReadinessProps = undefined;
	model.rlaStatusProps = undefined;
	model.benchmarkTestStatusProps = undefined;
	model.benchmarkTestStatusProps = undefined;
	model.scaledScoreReportProps = undefined;
	model.sidePanelProps = undefined;
	model.editSchoolYearModalProps = undefined;
	model.editUsageModalProps = undefined;
	model.editRestrictAccessModalProps = undefined;
	model.districtStudentTestSettings = undefined;
	model.schoolStudentTestSettings = undefined;
	model.dateService = undefined;
	model.UsageKpiProps = { busy: true, onOpenUsage };
	model.GrowthKpiProps = { busy: true, onOpenGrowth };
	model.DashboardGrowthKpiProps = { busy: true, onOpenGrowth };
	model.ProgressKpiProps = { busy: true, onOpenProgress };
	model.DashboardOrganizationsTableProps = undefined;
	model.DashboardGroupsTableProps = undefined;
	model.managementTooltipProps = undefined;
	model.generatePDFModalProps = {
		buttonText: 'Print Roster',
		iconOnLeft: true,
		headerText: 'Generate Rosters',
		btnClass: 'organization-print-roster-button settings_link button float-left',
		onLoadData: printRosters,
		'data-testid': 'OrganizationPrintRosterButton'
	};

	model.busy = false;
	model.initialized = false;
	model.error = false;
	model.organization = {};
	model.showOrganization = true;
	model.currentState = undefined;
	model.importWizardUrl = '/import';
	model.hideGrowthTab = false;
	model.defaultAssessmentProvider = AssessmentProviders.ILE;
	model.showGalileoTestSettings = false;
	model.isNwea = false;
	model.isThirdPartyAssessment = false;
	model.galileoUrl = $rootScope.appendAuthSourceQueryParam(urls.galileoUrl);
	model.nweaUrl = urls.nweaUrl;
	model.imagineLearningUniversityUrl = urls.imagineLearningUniversityUrl;
	model.setupWizardUrl = urls.setupWizardUrl;
	model.isAdmin = false;
	FeatureFlagsService.evaluate('lit-sisense').then(function(result) {
		model.isAdmin = !!result && result === 'show' && ApplicationUserService.isAdmin();
	});
	model.dsdsDashboardUrl = urls.dsdsDashboardUrl;
	model.maestroUrl = urls.maestroUrl;

	model.defaultRange = undefined;
	model.dateRanges = {
		kready: undefined,
		usageAverage: undefined,
		usagePredicted: undefined,
		assessmentsGrowth: undefined,
		assessmentsBenchmark: undefined,
		assessmentsBenchmarkStatus: undefined,
		assessmentsBenchmarkGains: undefined,
		assessmentsBenchmarkPerformance: undefined,
		assessmentsLexile: undefined,
		assessmentsLexileStatus: undefined,
		assessmentsLexileGains: undefined,
		assessmentsLexilePerformance: undefined,
		assessmentsOverview: undefined,
		programLevelGains: undefined
	};

	model.last4Weeks = undefined;
	model.yearToDateRange = undefined;
	model.usageDate = undefined;

	model.last4weeksData = undefined;
	model.yearToDateData = undefined;

	model.organizationsTablePagination = { skip: 0, take: 10 };
	model.organizationsTableData = undefined;
	model.groupsTablePagination = { skip: 0, take: 10 };
	model.groupsTableData = undefined;

	model.gradeLevels = {
		current: undefined,
		programLevelGains: undefined,
		usageAverage: undefined,
		usagePredicted: undefined
	};

	model.lexileTest = TestRanges.currentTestRange(CurrentDate.get()) || TestRanges.beginning.value;

	model.programLevelGainsMode = undefined;
	model.programLevelGainsGrade = undefined;

	model.csvExportAvailableCheck = true;
	model.csvExportAvailableDateTime = undefined;
	model.syncedChildFound = false;
	//methods
	model.stateChangeSuccess = stateChangeSuccess;
	model.showManagementTooltip = showManagementTooltip;
	model.closeManagementTooltip = closeManagementTooltip;
	model.printUsage = printUsage;
	model.edit = edit;

	model.userData = _.pick(ApplicationUserService.getUser(), ['id', 'username', 'userRole']);
	model.httpBusy = function() {
		return HttpBusyService.isBusy();
	};

	model.mainpanelProps = {
		config: ReactDependenciesService.apiConfig(),
		onOrganizationRowClick: onOrganizationRowClick,
		onGroupsRowClick: onGroupsRowClick,
		onGroupRowClick: onGroupRowClick,
		onOpenGrowth: onOpenGrowth,
		onOpenUsage: onOpenUsage,
		onOpenProgress: onOpenProgress,
		source: { type: 'organization', value: model.organization }
	};

	$scope.$on('csvDownloadDone', _onModalClose);

	function onOrganizationRowClick(id) {
		$state.go('dashboard.organization.overview', { id, start: model.last4Weeks.start, end: model.last4Weeks.end }, { notify: true });
	}

	function onGroupsRowClick(id) {
		$state.go('dashboard.group.overview', { id, start: model.last4Weeks.start, end: model.last4Weeks.end }, { notify: true });
	}

	function onGroupRowClick(id) {
		$state.go('dashboard.group.overview', { id, start: model.thisWeek.start, end: model.thisWeek.end }, { notify: true });
	}

	function onOpenUsage() {
		$state.go('dashboard.organization.usage.average', { id: model.organization.id });
	}

	function onOpenGrowth(assessmentProvider) {
		if (assessmentProvider === 'NWEA') {
			$window.location.href = model.nweaUrl;
		} else if (assessmentProvider === 'ATI') {
			$window.location.href = model.galileoUrl;
		} else {
			$state.go('dashboard.organization.assessments.benchmark', { id: model.organization.id });
		}
	}

	function onOpenNewGrowth(assessmentProvider, test, testWindows) {
		if (assessmentProvider === 'NWEA') {
			$window.location.href = model.nweaUrl;
		} else if (assessmentProvider === 'ATI') {
			$window.location.href = model.galileoUrl;
		} else {
			$state.go('dashboard.growth', {
				...model.growthNavParams,
				test: test,
				testWindows: testWindows?.map(w => w[0].toUpperCase()).join('')
			});
		}
	}

	function onOpenProgress() {
		$state.go('dashboard.organization.progress', { id: model.organization.id });
	}

	function _onModalClose() {
		CsvDownloadModal.close();
	}

	function printRosters() {
		return pdfReportService(ReactDependenciesService.apiConfig()).organizationClassRoster(model.organization.id);
	}

	function printUsage(summary, batch) {
		var gradeFetch = _.get(model, 'gradeLevels.usageAverage'),
			grade = gradeFetch && gradeFetch.toLowerCase() !== 'all' ? gradeFetch : undefined;
		GeneratePdfModal.generate(
			ReportPrinting.usage.organization({
				id: model.organization.id,
				summaryPage: summary,
				batchPages: batch,
				startDate: model.dateRanges.usageAverage.start,
				grade: grade,
				endDate: model.dateRanges.usageAverage.end
			}),
			'Generate Usage Reports',
			undefined,
			model.organization.name + ' Usage.pdf'
		);
	}

	// function edit(itemType, id, sections, callback)
	function edit(section) {
		ManagerModalFactory.edit('organization', model.organization.id, [section]);
	}

	$scope.$on('$stateChangeSuccess', stateChangeSuccess);

	$scope.$on('dashboard.reload', function() {
		if (model.organization && model.organization.id) {
			refreshModel(model.organization.id);
		}
	});

	function updateOrganizationDetails() {
		ReportingAPI.organization
			.unassignedGroup({
				// grade: undefined,
				id: model.organization.id,
				startDate: model.session.start,
				endDate: model.session.start,
				dataFields: ['counts']
			})
			.$promise.then(function(results) {
				model.unassignedStudentCount = results && results.totalStudents ? results.totalStudents : 0;
				return ReportingAPI.gradeLevels({
					id: model.organization.id,
					startDate: model.yearToDateRange.start,
					endDate: model.yearToDateRange.end,
					dataFields: ['counts']
				});
			})
			.then(function(results) {
				model.gradeLevels.current =
					results && results.items
						? _.map(
								_.filter(results.items, function(item) {
									return item.totalStudents > 0;
								}),
								'gradeLevel'
						  )
						: [];

				model.gradeLevels.current = _.sortBy(model.gradeLevels.current, [
					function(gradeLevel) {
						return GradeLevel.getGradeLevelNumber(gradeLevel);
					}
				]);

				model.kready = _.indexOf(model.gradeLevels.current, 'PreK') !== -1;
			})
			.catch(function(error) {
				NotificationFactory.error(error);
			});

		model.AssessmentProviderPromise = AssessmentProvider.getForOrgAndChildren(model.organization.id)
			.then(provider => {
				model.defaultAssessmentProvider = provider.defaultAssessmentProvider;
				model.hideGrowthTab =
					provider.defaultAssessmentProvider === AssessmentProviders.ATI ||
					provider.defaultAssessmentProvider === AssessmentProviders.NWEA;
				model.isThirdPartyAssessment = model.hideGrowthTab;
				model.showGalileoTestSettings = provider.defaultAssessmentProvider === AssessmentProviders.ATI;
				// Update the sidepanel props for this
				model.sidePanelProps = {
					...model.sidePanelProps,
					assessmentProvider: provider.defaultAssessmentProvider,
					showGalileoTestSettings: model.showGalileoTestSettings
				};
				model.isNwea = provider.defaultAssessmentProvider === AssessmentProviders.NWEA;
			})
			.catch(error => NotificationFactory.error(error));
	}

	function updateDashboardWidgetData() {
		ReportingAPI.organization
			.get({
				id: model.organization.id,
				startDate: model.usageDate,
				endDate: !model.last4Weeks.end || model.last4Weeks.end > CurrentDate.get() ? CurrentDate.get() : model.last4Weeks.end,
				dataFields: [DataFields.counts, UsageServiceIntervals.weeklyUsage, DataFields.gradeLevels]
			})
			.then(function(result) {
				model.last4weeksData = result;
				model.UsageKpiProps = {
					config: ReactDependenciesService.apiConfig(),
					data: model.last4weeksData,
					dateRange: { ...model.last4Weeks, start: model.usageDate },
					product: 'ILE',
					busy: !model.last4weeksData || !model.last4Weeks,
					onOpenUsage
				};
			})
			.catch(function(error) {
				NotificationFactory.error(error);
			});

		ReportingAPI.organization
			.get({
				id: model.organization.id,
				startDate: model.yearToDateRange.start,
				endDate:
					!model.yearToDateRange.end || model.yearToDateRange.end > CurrentDate.get()
						? CurrentDate.get()
						: model.yearToDateRange.end,
				dataFields: [
					DataFields.performance,
					DataFields.progress,
					DataFields.counts,
					DataFields.benchmarkTests,
					DataFields.lexileTestStatuses
				]
			})
			.then(function(results) {
				model.yearToDateData = results;

				model.GrowthKpiProps = {
					config: ReactDependenciesService.apiConfig(),
					assessmentProvider: AssessmentProviders[model.defaultAssessmentProvider],
					onOpenGrowth: onOpenGrowth,
					organizationId: model.organization.id,
					data: model.yearToDateData,
					error: model.error,
					busy: !model.defaultAssessmentProvider || !model.yearToDateData,
					type: ManagerEntityTypes.Organization
				};

				model.ProgressKpiProps = {
					config: ReactDependenciesService.apiConfig(),
					data: model.yearToDateData,
					type: ManagerEntityTypes.Organization,
					busy: !model.yearToDateData,
					onOpenProgress
				};
			})
			.catch(function(error) {
				NotificationFactory.error(error);
			});
	}

	function success() {
		NotificationFactory.success({
			heading: $filter('translate')('forms_common.messages.updatedHeader'),
			content: `${model.organization && model.organization.name ? model.organization.name : ''} ${$filter('translate')(
				'forms_common.messages.updatedMessage'
			)}`,
			closeAfter: 5
		});
	}

	function closeManagementTooltip() {
		const anchorElement = document.querySelector('[il-feature-flipper="import.wizard"]');
		anchorElement.classList.remove('hover-state');
		model.managementTooltipProps = undefined;
	}

	function showManagementTooltip() {
		const anchorElement = document.querySelector('[il-feature-flipper="import.wizard"]');
		anchorElement.classList.add('hover-state');

		model.managementTooltipProps = {
			anchorObject: anchorElement,
			isOpen: true,
			toolTitle: 'Import Wizard',
			onClose: model.closeManagementTooltip
		};
	}

	function error() {
		NotificationFactory.error(error);
	}

	function refreshModel(id) {
		if (model.busy || model.initialized === true) {
			return $q.when();
		}

		$localStorage.selectedStudents = [];
		model.busy = true;
		model.error = false;

		var params = {
			additionalFields: [
				'organizations',
				'groups',
				'parentName',
				'schoolYearSessions',
				'lexileSettingsBoy',
				'lexileSettingsMoy',
				'lexileSettingsEoy',
				'growthSettingsBoy',
				'growthSettingsMoy',
				'growthSettingsEoy'
			]
		};

		if (!id) {
			return User.get(ApplicationUserService.getUser().id).then(function(result) {
				$state.go('dashboard.organization.overview', { id: result.organizationIds[0] }, { notify: true });
				return $q.when();
			});
		}

		model.editSchoolYearModalProps = {
			config: ReactDependenciesService.apiConfig(),
			organizationId: id,
			success: success,
			error: error,
			onManagementModalOpen,
			onManagementModalClose
		};

		model.editRestrictAccessModalProps = {
			config: ReactDependenciesService.apiConfig(),
			organizationId: id,
			success: success,
			error: error,
			onManagementModalOpen,
			onManagementModalClose
		};

		model.districtStudentTestSettings = {
			config: ReactDependenciesService.apiConfig(),
			organizationId: id,
			onManagementModalOpen,
			onManagementModalClose
		};

		model.schoolStudentTestSettings = {
			config: ReactDependenciesService.apiConfig(),
			organizationId: id,
			userRole: model.userData.userRole,
			onManagementModalOpen,
			onManagementModalClose
		};

		const _organizationService = organizationService(ReactDependenciesService.apiConfig());
		return Promise.all([_organizationService.get(id, params).toPromise(), _organizationService.organizations(id, params).toPromise()])
			.then(function(results) {
				model.organization = results[0];
				model.organization.organizations = results[1];
				model.organization.organizationCount = model.organization.organizations ? model.organization.organizations.length : 0;
				model.organization.groupCount = model.organization.groups ? model.organization.groups.length : 0;
				model.organization.session = model.session = DateUtils.getCurrentSchoolYearDates(model.organization.schoolYearSessions);
				model.dateService = dateService(model.session);

				model.yearToDateRange = model.dateService.yearToDate(model.session);
				model.last4Weeks = model.dateService.last4Weeks(model.organization.session);
				model.thisWeek = model.dateService.thisWeek(model.organization.session);

				model.usageDate = new Date(model.last4Weeks.end.getTime());
				model.usageDate.setHours(0, 0, 0, 0);
				model.usageDate.setDate(model.usageDate.getDate() - 21);

				model.generatePDFModalProps = { ...model.generatePDFModalProps, fileName: model.organization.name + ' Rosters.pdf' };

				model.mainpanelProps.source.value = model.organization;
				model.mainpanelProps.session = model.organization.session;

				const dateHelpers = new DateHelpers();
				model.showSchoolYearWarningBanner = !dateHelpers.isWithinRange(
					CurrentDate.get(),
					model.organization.session.start,
					model.organization.session.end
				);
				const schoolYearWarningBannerDate = new Date(model.organization.session.start);
				schoolYearWarningBannerDate.setFullYear(schoolYearWarningBannerDate.getFullYear() + 1);
				model.schoolYearWarningBannerProps = {
					startDate: schoolYearWarningBannerDate
				};

				model.editUsageModalProps = {
					config: ReactDependenciesService.apiConfig(),
					organizationId: id,
					parentName: model.organization.parentName,
					onManagementModalOpen,
					onManagementModalClose
				};
				model.growthNavParams =
					model.organization.organizationType === 'School'
						? {
								districtId: model.organization.parentId,
								schoolId: model.organization.id
						  }
						: {
								districtId: model.organization.id
						  };

				return User.get(ApplicationUserService.getUser().id);
			})
			.then(function(result) {
				model.showOrganization = result.organizationIds[0] !== model.organization.id;
				return model.organization && model.organization.id ? Organization.siteCode(model.organization.id) : $q.when();
			})
			.then(function(result) {
				model.organization.siteCode = result; // model.organization.siteCode && model.organization.siteCode.length ? model.organization.siteCode : result;
				return _organizationService.children(model.organization.id, { limit: -1 });
			})
			.then(function(result) {
				if (result && result.length) {
					_.forEach(result, function(org) {
						if (org && org.syncId && org.syncId.length) {
							model.syncedChildFound = true;
							return false;
						}
					});
				}
			})
			.catch(function(error) {
				model.error = NotificationFactory.error(error);
			})
			.finally(function() {
				model.busy = false;
				model.initialized = true;
				model.importWizardUrl = urls.setupWizardUrl + '?wizardReturnUrl=' + encodeURIComponent(window.location.pathname);
			});
	}

	function usageReportOrganizationTableRowClick(id, start, end) {
		$state.go('dashboard.organization.usage.average', { id, start, end }, { notify: true });
	}

	function usageReportGroupTableRowClick(id, start, end) {
		$state.go('dashboard.group.usage.average', { id, start, end }, { notify: true });
	}

	function usageReportDateRangeChanged(dateRange) {
		model.dateRanges.usageAverage = dateRange;
		DateRangeService.updateRangeParams(model.dateRanges.usageAverage, $state);
	}

	function usageReportUnassignedStudentsLinkClick() {
		ngDialog.open({
			width: '100%',
			height: '100%',
			className: 'ngdialog-theme-default full',
			template: 'components/students.table/modal.html',
			controller: 'StudentsTableModalController',
			controllerAs: '$ctrl',
			closeByNavigation: true,
			closeByDocument: false,
			data: {
				titleText: 'Assign Students to Groups',
				source: model.organization,
				sourceType: 'organization',
				mode: 'unassigned'
			}
		});
	}

	function usagePredictedOrganizationTableRowClick(id, start, end) {
		$state.go('dashboard.organization.usage.predicted', { id, start, end }, { notify: true });
	}

	function usagePredictedGroupTableRowClick(id, start, end) {
		$state.go('dashboard.group.usage.predicted', { id, start, end }, { notify: true });
	}

	function usagePredictedDateRangeChanged(dateRange) {
		model.dateRanges.usagePredicted = dateRange;
		DateRangeService.updateRangeParams(model.dateRanges.usagePredicted, $state);
	}

	function onProgramGainsOrganizationRowClick(id) {
		$state.go('dashboard.organization.progress.programLevelGains', { id }, { notify: true });
	}

	function onProgramGainsGradeOnChange(grade) {
		$state.go('dashboard.organization.progress.programLevelGains', { grade }, { notify: false });
	}

	function onProgramGainsGroupRowClick(id) {
		$state.go('dashboard.group.progress.byLesson', { id }, { notify: true });
	}

	function growthReportOrganizationTableRowClick(id) {
		$state.go('dashboard.organization.assessments.benchmark.gains', { id }, { notify: true });
	}
	function growthRLAReportOrganizationTableRowClick(id) {
		$state.go('dashboard.organization.assessments.lexile.gains', { id }, { notify: true });
	}
	function growthReportGroupTableRowClick(id) {
		$state.go('dashboard.group.assessments.benchmark', { id }, { notify: true });
	}
	function growthRLAReportGroupTableRowClick(id) {
		$state.go('dashboard.group.assessments.lexile', { id }, { notify: true });
	}
	function growthReportGradeTableRowClick(grade) {
		$state.go('dashboard.organization.assessments.benchmark.gains', { grade }, { notify: false });
	}
	function growthRLAReportGradeTableRowClick(grade) {
		$state.go('dashboard.organization.assessments.lexile.gains', { grade }, { notify: false });
	}
	function growthReportDateRangeChanged(dateRange) {
		model.dateRanges.assessmentsBenchmarkGains = dateRange;
		DateRangeService.updateRangeParams(model.dateRanges.assessmentsBenchmarkGains, $state);
	}
	function growthRLAReportDateRangeChanged(dateRange) {
		model.dateRanges.assessmentsLexileGains = dateRange;
		DateRangeService.updateRangeParams(model.dateRanges.assessmentsLexileGains, $state);
	}
	function growthReportGradeChange(grade) {
		$state.go('dashboard.organization.assessments.benchmark.gains', { grade }, { notify: false });
	}
	function growthRLAReportGradeChange(grade) {
		$state.go('dashboard.organization.assessments.lexile.gains', { grade }, { notify: false });
	}
	function growthReportTabClick(tab) {
		$state.go('dashboard.organization.assessments.benchmark.' + tab, {}, { notify: true });
	}
	function growthRLAReportTabClick(tab) {
		$state.go('dashboard.organization.assessments.lexile.' + tab, {}, { notify: true });
	}
	function growthReportCurriculumChange(curriculum) {
		$state.go('dashboard.organization.assessments.benchmark.gains', { curriculum }, { notify: false });
	}
	function growthReportQualifierChange(qualifier) {
		$state.go('dashboard.organization.assessments.benchmark.gains', { qualifier }, { notify: false });
	}
	function growthRLAReportQualifierChange(qualifier) {
		$state.go('dashboard.organization.assessments.lexile.gains', { qualifier }, { notify: false });
	}
	function kindergartenReadinessNameClicked(id) {
		if (model.organization.organizationCount || model.organization.organizationType !== 'School') {
			$state.go('dashboard.organization.progress.kready', { id }, { notify: true });
		} else {
			$state.go('dashboard.group.progress.kready', { id }, { notify: true });
		}
	}

	function lexileStatusOnOrgGroupClicked(id, grade) {
		GrowthGradeFilter.setCurrentGrade(grade);
		if (model.organization.organizationType === 'School') {
			$state.go('dashboard.group.assessments.lexile', { id, grade }, { notify: true });
		} else {
			$state.go('dashboard.organization.assessments.lexile.status', { id, grade }, { notify: true });
		}
	}

	function readingLevelAssessmentStatusReportGradeChange(grade) {
		GrowthGradeFilter.setCurrentGrade(grade);
		$state.go('dashboard.organization.assessments.lexile.status', { grade }, { notify: false });
	}

	function readingLevelAssessmentReportTabClick(tab) {
		$state.go('dashboard.organization.assessments.lexile.' + tab, {}, { notify: true });
	}

	function benchmarkTestStatusOnGroupClicked(id, grade) {
		GrowthGradeFilter.setCurrentGrade(grade);
		if (model.organization.organizationType === 'School') {
			$state.go('dashboard.group.assessments.benchmark', { id, grade }, { notify: true });
		} else {
			$state.go('dashboard.organization.assessments.benchmark.status', { id }, { notify: true });
		}
	}

	function benchmarkTestStatusReportGradeChange(grade) {
		GrowthGradeFilter.setCurrentGrade(grade);
		$state.go('dashboard.organization.assessments.benchmark.status', { grade }, { notify: false });
	}

	function onBenchmarkTestPerformanceChangeView(view) {
		$state.go(`dashboard.organization.assessments.benchmark.${view}`, { notify: true });
	}

	function onBenchmarkTestPerformanceRowClick(id, grouping) {
		if (grouping === 'Grade') {
			GrowthGradeFilter.setCurrentGrade(id); //TODO: Needed for group level. Once the group level is rewritten in react, remove this.
			$state.go('dashboard.organization.assessments.benchmark.performance', { grade: id }, { notify: true });
		} else if (grouping === 'Organization') {
			$state.go('dashboard.organization.assessments.benchmark.performance', { id: id }, { notify: true });
		} else if (grouping === 'Group') {
			$state.go('dashboard.group.assessments.benchmark', { id: id }, { notify: true });
		}
	}
	function onBenchmarkTestPerformanceChangeGrade(grade) {
		GrowthGradeFilter.setCurrentGrade(grade); //TODO: Needed for group level. Once the group level is rewritten in react, remove this.
		$state.go('dashboard.organization.assessments.benchmark.performance', { grade }, { notify: true });
	}

	function onBenchmarkTestPerformanceChangeCurriculumArea(curriculum) {
		$state.go('dashboard.organization.assessments.benchmark.performance', { curriculum }, { notify: true });
	}

	function onBenchmarkTestPerformanceChangeQualifier(qualifier) {
		$state.go('dashboard.organization.assessments.benchmark.performance', { qualifier }, { notify: true });
	}

	function onReadingLevelAssessmentPerformanceRowClick(id, grouping) {
		if (grouping === 'Grade') {
			GrowthGradeFilter.setCurrentGrade(id); //TODO: Needed for group level. Once the group level is rewritten in react, remove this.
			$state.go('dashboard.organization.assessments.lexile.performance', { grade: id }, { notify: true });
		} else if (grouping === 'Organization') {
			$state.go('dashboard.organization.assessments.lexile.performance', { id: id }, { notify: true });
		} else if (grouping === 'Group') {
			$state.go('dashboard.group.assessments.lexile', { id: id }, { notify: true });
		}
	}

	function onReadingLevelAssessmentPerformanceChangeGrade(grade) {
		GrowthGradeFilter.setCurrentGrade(grade); //TODO: Needed for group level. Once the group level is rewritten in react, remove this.
		$state.go('dashboard.organization.assessments.lexile.performance', { grade }, { notify: true });
	}

	function onReadingLevelAssessmentPerformanceChangeQualifier(qualifier) {
		$state.go('dashboard.organization.assessments.lexile.performance', { qualifier }, { notify: false });
	}

	function scaledScoreOrgRowClicked(id) {
		const params = {
			id
		};
		const dates =
			!!model.dateRanges.assessmentsGrowth?.start && !!model.dateRanges.assessmentsGrowth?.end
				? {
						start: DateRangeService.getDateString(model.dateRanges.assessmentsGrowth.start),
						end: DateRangeService.getDateString(model.dateRanges.assessmentsGrowth.end)
				  }
				: undefined;
		if (!!dates) {
			params.start = dates.start;
			params.end = dates.end;
		}
		if (model.organization.organizationCount || model.organization.organizationType !== 'School') {
			$state.go('dashboard.organization.assessments.growth', params, { notify: true });
		} else {
			$state.go('dashboard.group.assessments.growth', params, { notify: true });
		}
	}

	function scaledScoreDateRangeChange(dateRange) {
		model.dateRanges.assessmentsGrowth = dateRange;
		DateRangeService.updateRangeParams(model.dateRanges.assessmentsGrowth, $state);
	}

	function onAssignStudentsProducts(students) {
		$localStorage.selectedStudents = students;
		$localStorage.assignStudentsProductsReturnUrl = $window.location.href;
		$window.location.href = $rootScope.appendAuthSourceQueryParam(urls.setupWizardUrl + '/assign-product-access');
	}

	function onAddStudent() {
		$window.location.href = $rootScope.appendAuthSourceQueryParam('/import');
	}

	function onSetupWizardClicked() {
		$window.location.href = $rootScope.appendAuthSourceQueryParam(model.setupWizardUrl);
	}

	function onBenchmarkSchedulerClicked() {
		$window.location.href = $rootScope.appendAuthSourceQueryParam(
			urls.galileoUrl + '?landingURL=' + encodeURIComponent('/GalileoASP/QuickTestScheduler')
		);
	}

	// PENDO LOCATION_API: remove when using routes
	function onManagementModalOpen(modalType) {
		PendoService.pendoService.updateLocationWithQueryParam(modalType ?? 'managementModal');
	}
	function onManagementModalClose() {
		PendoService.pendoService.revertToBrowserUrl();
	}

	function stateChangeSuccess(event, toState, toParams, fromState, fromParams) {
		var route;
		model.currentState = toState.name;

		// default routes
		if (toState.name && toState.name === 'dashboard.organization') {
			return $state.go(
				'dashboard.organization.overview',
				{ id: toParams && toParams.id ? toParams.id : undefined },
				{ notify: true }
			);
		}

		if (toState.name && toState.name === 'dashboard.organization.usage') {
			return $state.go(
				'dashboard.organization.usage.average',
				{ id: toParams && toParams.id ? toParams.id : undefined },
				{ notify: true }
			);
		}

		if (toState.name && toState.name === 'dashboard.organization.assessments') {
			return $state.go(
				'dashboard.organization.assessments.benchmark',
				{ id: toParams && toParams.id ? toParams.id : undefined },
				{ notify: true }
			);
		}

		if (toState.name && toState.name === 'dashboard.organization.progress') {
			return $state.go(
				'dashboard.organization.progress.programLevelGains',
				{ id: toParams && toParams.id ? toParams.id : undefined },
				{ notify: true }
			);
		}

		if (toState.name && toState.name === 'dashboard.organization.progress.programLevelGains') {
			model.programLevelGainsMode = toParams && toParams.curriculum ? toParams.curriculum : model.programLevelGainsMode || 'Literacy';
			model.programLevelGainsGrade = toParams && toParams.grade ? toParams.grade : model.programLevelGainsGrade || 'All';
		}

		if (toState.name && toState.name === 'dashboard.organization.assessments.lexile') {
			route = 'dashboard.organization.assessments.lexile.' + GrowthViewFilter.getCurrentView();
			return $state.go(route, { id: toParams && toParams.id ? toParams.id : undefined }, { notify: true });
		}

		if (toState.name && toState.name === 'dashboard.organization.assessments.benchmark') {
			route = 'dashboard.organization.assessments.benchmark.' + GrowthViewFilter.getCurrentView();
			return $state.go(route, { id: toParams && toParams.id ? toParams.id : undefined }, { notify: true });
		}
		// default routes

		if (toState.name && toState.name.match(/dashboard.organization./gi)) {
			// stash away range in params to allow drill down continuity
			model.defaultRange =
				toParams.start !== undefined && toParams.end !== undefined
					? DateRangeService.getRange(toParams.start, toParams.end || CurrentDate.get())
					: undefined;
			refreshModel(toParams.id).then(function() {
				updateOrganizationDetails();
				if (toState.name === 'dashboard.organization.usage.average') {
					model.dateRanges.usageAverage =
						model.defaultRange || model.dateRanges.usagePredicted || model.dateRanges.usageAverage || model.last4Weeks;
					DateRangeService.updateRangeParams(model.dateRanges.usageAverage, $state);

					model.organizationUsageReportProps = {
						config: ReactDependenciesService.apiConfig(),
						entityDetails: model.organization,
						session: model.organization.session,
						onOrganizationTableRowClick: usageReportOrganizationTableRowClick,
						onGroupTableRowClick: usageReportGroupTableRowClick,
						onDateRangeChanged: usageReportDateRangeChanged,
						onUnassignedStudentsLinkClick: usageReportUnassignedStudentsLinkClick,
						params: {
							startDate: model.dateRanges.usageAverage.start,
							endDate: model.dateRanges.usageAverage.end,
							product: 'ILE'
						},
						hideGroupsTable: !model.organization.groups.length,
						hideOrganizationsTable: !model.organization.organizations.length
					};
				}
				if (toState.name === 'dashboard.organization.overview') {
					updateDashboardWidgetData();

					model.AssessmentProviderPromise.then(function() {
						function setDashboardKpiProps() {
							model.DashboardGrowthKpiProps = {
								config: ReactDependenciesService.apiConfig(),
								growthApi: $rootScope.growthReportApi,
								assessmentProvider: AssessmentProviders[model.defaultAssessmentProvider],
								type: model.organization.organizationType === 'District' ? GrowthViews.District : GrowthViews.School,
								onOpenGrowth: onOpenNewGrowth,
								id: model.organization.id,
								busy: !model.defaultAssessmentProvider
							};
						}
						$rootScope.growthReportApi
							? setDashboardKpiProps()
							: $rootScope.$on(AuthenticationServiceEvents.growthReportRolloverLoaded, () => {
									setDashboardKpiProps();
							  });
					});

					model.DashboardGroupsTableProps = {
						config: ReactDependenciesService.apiConfig(),
						organization: model.organization,
						session: model.organization.session,
						onGroupRowClick: onGroupsRowClick
					};
					model.DashboardOrganizationsTableProps = {
						config: ReactDependenciesService.apiConfig(),
						organization: model.organization,
						session: model.organization.session,
						onOrganizationRowClick: onOrganizationRowClick
					};

					model.sidePanelProps = {
						config: ReactDependenciesService.apiConfig(),
						userRole: model.userData.userRole,
						organizationId: model.organization.id,
						source: model.organization,
						sourceType: model.organization.organizationType === 'District' ? 'district' : 'school',
						showGalileoTestSettings: model.showGalileoTestSettings,
						userId: model.userData.id,
						onBenchmarkSchedulerClicked,
						onAssignStudentsProducts,
						onAddStudent,
						onSetupWizardClicked,
						onManagementModalOpen,
						onManagementModalClose,
						params: {
							startDate: model.session.start,
							endDate: model.session.start,
							product: 'ILE'
						}
					};
				}
				if (toState.name === 'dashboard.organization.usage.predicted') {
					model.dateRanges.usagePredicted =
						model.defaultRange || model.dateRanges.usageAverage || model.dateRanges.usagePredicted || model.last4Weeks;
					DateRangeService.updateRangeParams(model.dateRanges.usagePredicted, $state);

					model.organizationUsagePredictedProps = {
						config: ReactDependenciesService.apiConfig(),
						entityDetails: model.organization,
						session: model.organization.session,
						onOrganizationTableRowClick: usagePredictedOrganizationTableRowClick,
						onGroupTableRowClick: usagePredictedGroupTableRowClick,
						onDateRangeChanged: usagePredictedDateRangeChanged,
						params: {
							startDate: model.dateRanges.usagePredicted.start,
							endDate: model.dateRanges.usagePredicted.end,
							product: 'ILE'
						}
					};
				}

				if (toState.name === 'dashboard.organization.assessments.growth') {
					const yearToDateText = [
						DateRangeService.getDisplayDateString(model.yearToDateRange.start),
						DateRangeService.getDisplayDateString(model.yearToDateRange.end)
					].join(' - ');
					if (
						model.dateRanges.assessmentsGrowth == undefined ||
						model.defaultRange == undefined ||
						(model.defaultRange !== undefined && model.defaultRange.text === yearToDateText)
					) {
						model.dateRanges.assessmentsGrowth = model.yearToDateRange;
					}
					if (model.defaultRange !== undefined && model.defaultRange.text !== yearToDateText) {
						model.dateRanges.assessmentsGrowth = model.defaultRange;
					}
					DateRangeService.updateRangeParams(model.dateRanges.assessmentsGrowth, $state);
					model.scaledScoreReportProps = {
						config: ReactDependenciesService.apiConfig(),
						session: model.organization.session,
						dateRange: model.dateRanges.assessmentsGrowth,
						sourceId: model.organization.id,
						onDateRangeChanged: scaledScoreDateRangeChange,
						onClick: scaledScoreOrgRowClicked
					};
				}

				if (toState.name === 'dashboard.organization.assessments.benchmark.gains') {
					model.grade = toParams.grade ? toParams.grade : undefined;
					model.qualifier = toParams.qualifier ? toParams.qualifier : undefined;
					model.curriculum = toParams.curriculum ? toParams.curriculum : undefined;

					GrowthGradeFilter.setCurrentGrade(model.grade);
					model.dateRanges.assessmentsBenchmarkGains =
						model.defaultRange || model.dateRanges.assessmentsBenchmarkGains || model.yearToDateRange;
					DateRangeService.updateRangeParams(model.dateRanges.assessmentsBenchmarkGains, $state);

					model.benchmarkTestGainsReportWithProvider = {
						config: ReactDependenciesService.apiConfig(),
						organization: model.organization,
						session: model.organization.session,
						onGrowthTabClick: growthReportTabClick,
						onOrganizationTableRowClick: growthReportOrganizationTableRowClick,
						onGroupTableRowClick: growthReportGroupTableRowClick,
						onDateRangeChanged: growthReportDateRangeChanged,
						onGradeTableRowClick: growthReportGradeTableRowClick,
						onGradeChange: growthReportGradeChange,
						onCurriculumChange: growthReportCurriculumChange,
						onQualifierChange: growthReportQualifierChange,
						params: {
							startDate: model.dateRanges.assessmentsBenchmarkGains.start,
							endDate: model.dateRanges.assessmentsBenchmarkGains.end,
							product: 'ILE',
							grade: model.grade,
							qualifier: model.qualifier,
							curriculum: model.curriculum
						}
					};
				}
				if (toState.name === 'dashboard.organization.assessments.benchmark.performance') {
					model.grade = toParams.grade;
					model.curriculum = toParams.curriculum;
					model.qualifier = toParams.qualifier;
					model.organizationBenchmarkTestPerformanceReportProps = {
						config: ReactDependenciesService.apiConfig(),
						organizationId: model.organization.id,
						gradeLevel: model.grade,
						curriculumArea: model.curriculum,
						qualifier: model.qualifier,
						onRowClick: onBenchmarkTestPerformanceRowClick,
						onChangeView: onBenchmarkTestPerformanceChangeView,
						onChangeGrade: onBenchmarkTestPerformanceChangeGrade,
						onChangeCurriculumArea: onBenchmarkTestPerformanceChangeCurriculumArea,
						onChangeQualifier: onBenchmarkTestPerformanceChangeQualifier
					};
				}
				if (toState.name === 'dashboard.organization.assessments.benchmark.status') {
					model.grade = toParams.grade ? toParams.grade : undefined;
					GrowthGradeFilter.setCurrentGrade(model.grade);
					model.dateRanges.assessmentsBenchmarkStatus =
						model.defaultRange || model.dateRanges.assessmentsBenchmarkStatus || model.yearToDateRange;
					DateRangeService.updateRangeParams(model.dateRanges.assessmentsBenchmarkStatus, $state);

					model.benchmarkTestStatusProps = {
						config: ReactDependenciesService.apiConfig(),
						organizationId: model.organization.id,
						dateRange: model.dateRanges.assessmentsBenchmarkStatus,
						isDistrictLevel: model.organization.organizationType === 'District',
						onOrgGroupClick: benchmarkTestStatusOnGroupClicked,
						onBenchmarkTabClick: growthReportTabClick,
						onGradeChange: benchmarkTestStatusReportGradeChange,
						params: {
							grade: model.grade,
							qualifier: model.qualifier
						}
					};
				}
				if (toState.name === 'dashboard.organization.assessments.lexile.status') {
					model.grade = toParams.grade ? toParams.grade : undefined;
					GrowthGradeFilter.setCurrentGrade(model.grade);
					model.qualifier = toParams.qualifier ? toParams.qualifier : undefined;
					model.dateRanges.assessmentsLexileStatus =
						model.defaultRange || model.dateRanges.assessmentsLexileStatus || model.yearToDateRange;
					DateRangeService.updateRangeParams(model.dateRanges.assessmentsLexileStatus, $state);

					model.rlaStatusProps = {
						config: ReactDependenciesService.apiConfig(),
						organizationId: model.organization.id,
						dateRange: model.dateRanges.assessmentsLexileStatus,
						isDistrictLevel: model.organization.organizationType === 'District',
						onOrgGroupClick: lexileStatusOnOrgGroupClicked,
						onRlsTabClick: readingLevelAssessmentReportTabClick,
						onGradeChange: readingLevelAssessmentStatusReportGradeChange,
						params: {
							grade: model.grade,
							qualifier: model.qualifier
						}
					};
				}
				if (toState.name === 'dashboard.organization.assessments.lexile.gains') {
					model.grade = toParams.grade ? toParams.grade : undefined;
					GrowthGradeFilter.setCurrentGrade(model.grade);
					model.qualifier = toParams.qualifier ? toParams.qualifier : undefined;
					model.dateRanges.assessmentsLexileGains =
						model.defaultRange || model.dateRanges.assessmentsLexileGains || model.yearToDateRange;
					DateRangeService.updateRangeParams(model.dateRanges.assessmentsLexileGains, $state);

					model.readingLevelAssessmentGainsReport = {
						config: ReactDependenciesService.apiConfig(),
						organization: model.organization,
						session: model.dateRanges.assessmentsLexileGains,
						onGrowthTabClick: growthRLAReportTabClick,
						onOrganizationTableRowClick: growthRLAReportOrganizationTableRowClick,
						onGroupTableRowClick: growthRLAReportGroupTableRowClick,
						onDateRangeChanged: growthRLAReportDateRangeChanged,
						onGradeTableRowClick: growthRLAReportGradeTableRowClick,
						onGradeChange: growthRLAReportGradeChange,
						onQualifierChange: growthRLAReportQualifierChange,
						params: {
							startDate: model.dateRanges.assessmentsLexileGains.start,
							endDate: model.dateRanges.assessmentsLexileGains.end,
							product: 'ILE',
							grade: model.grade,
							qualifier: model.qualifier
						}
					};
				}
				if (toState.name === 'dashboard.organization.assessments.lexile.performance') {
					model.grade = toParams.grade ? toParams.grade : undefined;
					GrowthGradeFilter.setCurrentGrade(model.grade);
					model.qualifier = toParams.qualifier ? toParams.qualifier : undefined;
					model.dateRanges.assessmentsLexilePerformance =
						model.defaultRange || model.dateRanges.assessmentsLexilePerformance || model.yearToDateRange;
					DateRangeService.updateRangeParams(model.dateRanges.assessmentsLexilePerformance, $state);

					model.organizationReadingLevelAssessmentPerformanceReportProps = {
						config: ReactDependenciesService.apiConfig(),
						organizationId: model.organization.id,
						gradeLevel: model.grade,
						qualifier: model.qualifier,
						isDistrictLevel: model.organization.organizationType === 'District',
						onRowClick: onReadingLevelAssessmentPerformanceRowClick,
						onChangeView: readingLevelAssessmentReportTabClick,
						onChangeGrade: onReadingLevelAssessmentPerformanceChangeGrade,
						onChangeQualifier: onReadingLevelAssessmentPerformanceChangeQualifier
					};
				}
				if (toState.name === 'dashboard.organization.assessments.overview') {
					model.dateRanges.assessmentsOverview =
						model.defaultRange || model.dateRanges.assessmentsOverview || model.yearToDateRange;
					DateRangeService.updateRangeParams(model.dateRanges.assessmentsOverview, $state);
				}
				if (toState.name === 'dashboard.organization.progress.programLevelGains') {
					model.dateRanges.programLevelGains = model.defaultRange || model.dateRanges.programLevelGains || model.yearToDateRange;
					DateRangeService.updateRangeParams(model.dateRanges.programLevelGains, $state);

					model.grade = toParams.grade ? toParams.grade : undefined;
					model.qualifier = toParams.qualifier ? toParams.qualifier : undefined;

					model.organizationProgressReportProps = {
						config: ReactDependenciesService.apiConfig(),
						organization: model.organization,
						onOrganizationTableRowClick: onProgramGainsOrganizationRowClick,
						onGroupTableRowClick: onProgramGainsGroupRowClick,
						onGradeTableRowClick: onProgramGainsGradeOnChange,
						onGradeChange: onProgramGainsGradeOnChange,
						params: { product: ApiProduct.ILE, grade: model.grade },
						showKPI: true,
						session: model.organization.session,
						hideOrganizationsTable: !(model.organization.organizationCount && model.organization.organizationType !== 'School'),
						hideGroupsTable: !(model.organization.groupCount && model.organization.organizationType === 'School'),
						source: {
							type: 'organization',
							value: model.organization
						}
					};
				}
				if (toState.name === 'dashboard.organization.progress.kready') {
					model.dateRanges.kready = model.defaultRange || model.dateRanges.kready || model.yearToDateRange;
					DateRangeService.updateRangeParams(model.dateRanges.kready, $state);

					model.kindergartenReadinessProps = {
						config: ReactDependenciesService.apiConfig(),
						id: model.organization.id,
						type:
							model.organization.organizationCount || model.organization.organizationType !== 'School'
								? 'organizations'
								: 'groups',
						onClick: kindergartenReadinessNameClicked
					};
				}
				if (toState.name === 'dashboard.organization.progress.cumulativePerformance') {
					model.organizationProgressCumulativePerformanceReportProps = {
						config: ReactDependenciesService.apiConfig(),
						organization: model.organization,
						onOrganizationTableRowClick: onProgramGainsOrganizationRowClick,
						onGroupTableRowClick: onProgramGainsGroupRowClick,
						onGradeTableRowClick: onProgramGainsGradeOnChange,
						onGradeChange: onProgramGainsGradeOnChange,
						params: { product: ApiProduct.ILE, grade: model.grade },
						showKPI: true,
						session: model.organization.session,
						hideOrganizationsTable: !(model.organization.organizationCount && model.organization.organizationType !== 'School'),
						hideGroupsTable: !(model.organization.groupCount && model.organization.organizationType === 'School'),
						source: {
							type: 'organization',
							value: model.organization
						},
						isAdmin: model.isAdmin,
						dsdsUrl: model.dsdsDashboardUrl
					};
				}
			});
		}
	}
}
